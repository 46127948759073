@import "../../../scss/main.scss";

.header-image {
    background-image: url(/assets/scenarios/desktop/il-camino-rome.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 80vh;

    @media #{$max-mobile-screen} {
        background-image: url(/assets/scenarios/mobile/il-camino-rome-2.jpg);
        background-repeat: no-repeat;
        background-position: 0% 0%;
        background-size: 100% 100%;
        width: 100%;
        height: 25vh;
        object-fit: cover;
        margin-top: 23%;
    }
}

.outdoor-box {
    display: flex;
    justify-content: center;
    text-align: center;
}

.outdoor-title {
    font-size: 2.5rem;
    display: inline-block;
    font-weight: 900;
    line-height: 4rem;
    text-align: center;
}

.rome-p {
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 1.2em;
    text-align: left;

    @media #{$max-mobile-screen} {
        font-size: 1em !important;
    }
}

.underline {
    text-decoration-color: #da1a35;
}

.hr-rome {
    margin: 0 auto;
    color: #da1a35;
}

.sub-text {
    color: #434343;
    font-weight: 600;
    position: relative;
    font-size: 1.3em;
    white-space: nowrap;
    margin: 0 !important;
    padding: 10px;
    max-width: 100% !important;
}

.video-2-section {
    margin: 0 auto;
    width: fit-content;

    @media #{$max-mobile-screen} {
        width: 90%;
        justify-content: center;
    }
}

.blText {
    font-family: "Berkahi Blackletter";
    font-size: 7rem;
    justify-content: center;
    display: flex;
    line-height: 1em;
    align-items: center;
    position: relative;
    height: 40rem;

    @media #{$max-mobile-screen} {
        font-size: 2em;
        height: auto;
        text-align: center;
    }
}

.video_2 {
    width: 25rem;
    margin: 10rem;
    margin-top: -2rem !important;

    @media #{$max-mobile-screen} {
        width: 15rem;
        margin-top: unset !important;
        margin: 1rem;
    }
}

.outdoor-location {
    font-size: 2rem;
    padding-bottom: 0.1rem;
    border-bottom: 5px solid $primary-red;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 4rem;
}

#outdoor-room {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto auto 7px;
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translate(-50%);

    @media #{$max-mobile-screen} {
        top: 1rem;
    }
}

#name_span {
    font-weight: 400;
    font-size: 1.7rem;
    margin-top: -7%;
}

#outdoor-room img {
    width: 8rem;
    height: 8rem;
}

#room-name {
    font-size: 4.7rem;
    font-weight: 900;
}

#outdoor-description {
    white-space: pre-line;
    text-align: left;
    font-size: 21px;
    padding: 3rem;
    margin-bottom: 3rem;

    @media #{$max-mobile-screen} {
        text-align: left;
        padding: 1rem;
    }
}

.arabic_direction {
    direction: rtl;
    text-align: right !important;
}

.room-disclaimer {
    font-size: 14px;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.btnFoo {
    border-radius: 9px !important;
    font-size: 16px;
    width: 14em;
    font-weight: bold !important;
    margin: 20px;
    font-family: "Raleway", sans-serif !important;
    border-color: #da1a35;
    background-color: #da1a35;
    color: #ffffff;
    cursor: pointer;
}
.btnFoo a {
    color: #fff;
}

.imgRowFoo {
    padding: 2rem;
}

.outdoor-page-icon {
    width: 5rem !important;
    margin: 0 auto;
}

@media screen and (max-width: 700px) {
    #outdoor-room {
        margin-top: unset;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding: unset;

        > h1 {
            font-size: 1rem;
            line-height: unset;
            margin-top: -1rem;
            width: 150px;
        }

        img {
            width: 4rem;
            height: 4rem;
        }

        #name_span {
            margin-top: -2%;
        }

        #outdoor-description {
            padding: unset;
        }

        > p {
            font-size: 11px;
        }
    }

    .outdoor-page-icon {
        width: 2rem !important;
    }
}
