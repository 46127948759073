@import "../../scss/main.scss";

.the_external_content_of_PlantCity {
    margin-top: 2rem;

    @media (min-width: 992px) {
        padding-left: 18%;
        padding-right: 18%;
    }
}

.resova_voucher_iframe_Edinburgh,
.forescape_iframe_Plan,
.forescape_iframe_Riyadh {
    width: 100%;
    border: none;
}

.forescape_iframe_Marseille {
    width: 100%;
    border: none;
    margin-top: 5%;

    @media #{$max-tablet-screen} {
        margin-top: 10%;
    }
}

.forescape_iframe_voucher_Plan {
    border: none;
    margin: auto;
    display: flex;
    width: 58%;

    @media #{$max-tablet-screen} {
        width: 92%;
    }
}

@media (min-width: 992px) {
    .the_external_content_of_Geneva {
        margin: 10% auto 0% auto;
        width: 60%;
    }

    .forescape_iframe_Riyadh {
        width: 60%;
    }
}

@media #{$max-tablet-screen} {
    .the_external_content_of_Geneva {
        margin: 20% auto 0% auto;
        width: 90%;
    }

    .forescape_iframe_Marseille,
    .forescape_iframe_Plan {
        margin-top: 10%;
    }

    .forescape_iframe_voucher_Plan {
        width: 92%;
    }

    .forescape_iframe_Riyadh {
        width: 100%;
    }

    .the_external_content_of_BAIRRO {
        margin-top: 10%;
    }
}
