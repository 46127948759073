@import url("https://fonts.googleapis.com/css2?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Raleway");

body {
    background-color: #000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0;
        display: none;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
}

body.show-scrollbar::-webkit-scrollbar {
    width: 8px;
    display: block;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-radius: 30px;
}

body.show-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 30px;
}

body.show-scrollbar::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

body.show-scrollbar:hover::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
}

@mixin bars($width, $height, $color) {
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;
    background: $color;

    &:before {
        content: "";
        display: block;
        position: absolute;
        width: $width;
        height: $height;
        top: -($height + 2px);
        background: $color;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        width: $width;
        height: $height;
        top: $height + 2px;
        background: $color;
    }
}

.bars {
    @include bars(15px, 3px, white);
}
