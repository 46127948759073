@tailwind base;
@tailwind components;
@tailwind utilities;

code,
pre {
    font-family: var(--font-family-monospace);
}

html {
    max-width: 120rem;
    margin: 0 auto;
    scroll-behavior: smooth;
}

iframe {
    display: unset;
}

.scw-minus-cart > img,
.scw-plus-cart > img {
    display: none;
}

.scw-minus-cart::after {
    content: "-";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -54%);
    color: #da1a35;
    font-size: 25px;
    font-weight: bold;
}

.scw-plus-cart::after {
    content: "+";
    color: #da1a35;
    position: absolute;
    font-size: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.scw-minus-cart,
.scw-plus-cart {
    position: relative;
    height: 20px !important;
    width: 20px !important;
    display: block !important;
}

.scw-logo {
    display: none;
}

#scw-cta-order {
    background: #da1a35 !important;
    color: white !important;
}

#scw-payment-price {
    color: #c1c1c1;
}

.scw-container {
    background: black !important;
    border-radius: 5px !important;
}

.scw-button {
    height: 5% !important;
    width: 6% !important;
    border-radius: 30px !important;
    background-color: #da1a35;
    border: none;
    margin-top: 20px;
    color: white;
    font-weight: 900;
    font-size: 14px;
}

.rome-outside-title {
    font-family: var(--font-family-sans-serif) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto auto 7px;
    position: absolute;
    font-weight: 900;
    font-size: 1.3rem;
    letter-spacing: 1px;
    top: 6rem;
    left: 50%;
    transform: translate(-50%);
}

@media (max-width: 768px) {
    .scw-button {
        height: 60px !important;
        width: 100px !important;
        border-radius: 30px;
        background-color: #da1a35;
        border: none;
        margin-top: 20px;
        color: white;
        font-weight: 900;
        padding: -0.7rem 3.5rem;
        font-size: 14px;
    }

    .scw-container {
        left: 15px;
        min-width: 320px !important;
        background: black;
        border-radius: 5px !important;
    }

    .rome-outside-title {
        top: 3rem;
        text-align: center;
        font-size: 0.8rem;
        color: #ddd8d8;
        font-weight: 900;
        letter-spacing: 0px;
    }
}

.scw-ticket {
    background: #2b2b2b !important;
}

.scw-ticket .scw-col-auto {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.scw-button svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scw-cart {
    background: #161616 !important;
}
